import React from "react";

const UnAuthorized = () => {
  return (
    <div>
      <h1 className="text-center">403 - Un Authorized Access</h1>
    </div>
  );
};

export default UnAuthorized;
